const genders = [{
	key: "M",
	display: "Male"
}, {
	key: "F",
	display: "Female"
}, {
	key: "O",
	display: "Other"
}];

export default genders