const referralSources = [{
	key: "Friend",
	display: "Friend/Family"
}, {
	key: "Google"
}, {
	key: "Youtube"
}, {
	key: "School"
}, {
	key: "Facebook"
}, {
	key: "Groupon"
}, {
	key: "MBTA"
}, {
	key: "Twitter"
}, {
	key: "Walking By"
}, {
	key: "Other"
}]

export default referralSources