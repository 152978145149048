const ethnicities = [{
	key: "Asian/Pacific Islander"
}, {
	key: "Caucasian"
}, {
	key: "Native American"
}, {
	key: "African-American"
}, {
	key: "Hispanic"
}, {
	key: "Other"
}]

export default ethnicities