export const MAGIC_NUMBERS = {
	MEMBERSHIP_TYPE_ID: {
		FULL_YEAR: 1,
		MEM_30_DAY: 881,
		MEM_30_DAY_WICKED_BASIC: 4,
		MEM_60_DAY: 5,
		FULL_YEAR_PADDLING: 882
	},
	DISCOUNT_ID: {
		RENEWAL_DISCOUNT_ID: 8,
		YOUTH_DISCOUNT_ID: 2,
		SENIOR_DISCOUNT_ID: 1,
		STUDENT_DISCOUNT_ID: 3,
		MGH_DISCOUNT_ID: 7,
		VETERAN_DISCOUNT_ID: 442,
		MA_TEACHERS_ASSN: 243,
	},
	DONATION_FUND_ID: {
		PRIEBATSCH_ENDOWMENT: 1061,
		JP_OPERATIONS: 1,
		JP_STEM: 1221,
	}
};