import * as t from 'io-ts';

export const path = "/member/ap-cancel-teach-instance"

/**
 * !!!!!!!!!!!!
 * This file is AUTO-GENERATED by cbidb-schema
 * Do not manually alter this file, or your changes will be lost
 * !!!!!!!!!!!!
 */
export const responseSuccessValidator = t.string

export const requestValidator = t.type({
	instanceId: t.number,
})
