import * as t from 'io-ts';
import { OptionalString, OptionalNumber } from 'util/OptionalTypeValidators';

export const path = "/member/ap-class-instances-instructor-info"

/**
 * !!!!!!!!!!!!
 * This file is AUTO-GENERATED by cbidb-schema
 * Do not manually alter this file, or your changes will be lost
 * !!!!!!!!!!!!
 */
export const responseSuccessValidator = t.array(t.type({
	instanceId: t.number,
	instructorName: OptionalString,
	signupCt: t.number,
	signupMin: OptionalNumber,
	signupMax: OptionalNumber,
}))
